
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import { authHeader } from '../../../helpers/fakebackend/auth-header';
import { required } from "vuelidate/lib/validators";

/**
 * Advanced-form component
 */
export default {
  page: {
    title: "Operational Checklist Report Template",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
   Multiselect,
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Operational Checklist Report Template",
      items: [
        {
          text: "Operational Checklist Report Template",
          href: "/",
        },
        {
          text: "Add",
          active: true,
        },
      ],
      status :'',
      submitted: false,
      showDismissibleAlert :false,
      loading: false,
      templateTitle:"",
      restBranchArr:[],
      restBranchID:"",
      complianceTemplateID: 0,
      isEdit: false,
      complianceTemplateData: [],
      taskArr: [
        {
          id: 1,
          reportTaskID:0,
          tasklist:"",
          weightage:"",
          maxScore:"",
        },
      ],
      weightageArr: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
        18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34,
        35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51,
        52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68,
        69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85,
        86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100
      ]
    };
  },

  validations: {
    restBranchID: {
      required,
    },
    templateTitle: {
      required,
    },
  },

  computed: {
    totalWeightagePercent() {
      return this.taskArr.reduce((acc, item) => acc + parseInt(item.weightage), 0);
    },
    totalScore() {
      return this.taskArr.reduce((acc, item) => acc + parseInt(item.maxScore), 0);
    }
  },
  methods:{

    submitForm(){
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        window.scrollTo(0,0);
        return;
      } else {
        let formData = new FormData();  
  
        formData.append('templateTitle', this.templateTitle);
        formData.append('restBranchID', this.restBranchID.restBranchID);
        formData.append('taskArr',JSON.stringify(this.taskArr));
        formData.append('createdBy', this.$storageData.profile.pid);
        formData.append('loginTypeID',this.$storageData.login_type);

        this.axios.post(this.$loggedRole+"/addComplianceTemplate",formData, {
          headers: authHeader()
        })
        .then((result)=>{
          this.status = result.data.data;
          this.showDismissibleAlert = true;
          this.loading = false;
          
          setTimeout(() => {
              this.$router.push({name: 'complianceTemplateTable'}); 
          },2000);
        })
        .catch((error)=> {
          this.loading = false;
          alert(error.response.data.data);
        });
      }  
    },

    updateForm(){
      alert(JSON.stringify(this.taskArr));
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        window.scrollTo(0,0);
        return;
      } else {
        let formData = new FormData();  

        formData.append('complianceTemplateID',this.complianceTemplateID);
        formData.append('templateTitle', this.templateTitle);
        formData.append('restBranchID', this.restBranchID.restBranchID);
        formData.append('taskArr',JSON.stringify(this.taskArr));
        formData.append('createdBy', this.$storageData.profile.pid);
        formData.append('loginTypeID',this.$storageData.login_type);

        this.axios.post(this.$loggedRole+"/updateComplianceTemplate",formData, {
          headers: authHeader()
        })
        .then((result)=>{
          this.status = result.data.data;
          this.showDismissibleAlert = true;
          this.loading = false;
          
          setTimeout(() => {
              this.$router.push({name: 'complianceTemplateTable'}); 
          },2000);
        })
        .catch((error)=> {
          this.loading = false;
          alert(error.response.data.data);
        });
      }  
    },

    getAllRestaurantBranches(){
      this.axios.post(this.$loggedRole+"/getAllRestaurantBranches", {
        empTypeID:this.$storageData.profile.empTypeID,
        empID:this.$storageData.profile.pid,
        loginTypeID:this.$storageData.login_type
      })
      .then((result)=>{
        this.restBranchArr = result.data.data;
      });
    },

    AddItem() {
      this.taskArr.push({
        reportTaskID:0,
        tasklist:"",
        weightage:"",
        maxScore:"",
      });
    },

    getCorporateMealPlan(){
      this.axios.post(this.$loggedRole+"/getCorporateMealPlanFormData",
      {
        'corpMealPlanID':this.corpMealPlanID,
        empTypeID:this.$storageData.profile.empTypeID,
        empID:this.$storageData.profile.pid,
        loginTypeID:this.$storageData.login_type
      })
      .then((result)=>{
        this.mealPlanData = result.data.data;
        this.corpBranchID = {'corpBranchID':this.mealPlanData.corpBranchID,'corpBranchName':this.mealPlanData.corpBranchName}
        this.corpBranchID.corpBranchID = this.mealPlanData.corpBranchID;
        this.getCorporateCafeteria(this.corpBranchID.corpBranchID);
        this.getHolidayList(this.mealPlanData.corporateID);
        this.applicableTo = this.mealPlanData.applicableTo;
        this.showSellingPrice = this.mealPlanData.showSellingPrice;     
        this.startDate = this.mealPlanData.startDate;
        this.endDate = this.mealPlanData.endDate;
        this.holidayID = result.data.holidayArr;   
        this.restBranch = this.mealPlanData.restDetails;
        this.selectedDepartment = result.data.depData;
        this.selectedEmployees = result.data.selectedUsers;
        this.taskArr = (result.data.mealPlans!="") ? result.data.mealPlans : [
          {
            id: 1,
            mealTypeName: "",
            discountType: "",
            discountInRs: "",
            discountPercent:"",
            maxDiscountCapping:"",
            corpMealID:0,
          
          },
        ];
      });
    },

    deleteItem(index,reportTaskID) {
      if(reportTaskID > 0){
        if (confirm("Are you sure you want to delete this element?")) {
          this.axios.post(this.$loggedRole+"/deleteComplianceTemplateTask", {'reportTaskID': reportTaskID})
          .then(()=>{
            this.taskArr.splice(index, 1);
          });
        }
      }else{
        this.taskArr.splice(index, 1);
      }
    },

    getcomplianceTemplateByID(){
      this.axios.post(this.$loggedRole+"/getcomplianceTemplateByID",
        {'complianceTemplateID':this.complianceTemplateID})
      .then((result)=>{
        this.complianceTemplateData = result.data.data;
        this.templateTitle = this.complianceTemplateData.templateTitle;
        this.restBranchID = this.complianceTemplateData.restaurantData;
        this.taskArr = this.complianceTemplateData.complianceReportTask;
      });
    },
  },

  created(){
    this.corpMealPlanID = this.$route.params.id;
    if(this.corpMealPlanID){
      sessionStorage.setItem('corpMealPlanID',this.corpMealPlanID);// Save to sessionStorage
    }
    if(this.$route.params.type=='edit'){
      this.isEdit = 1;
    }else{
      this.isEdit = 0;
    }
  },

  mounted(){
    this.getAllRestaurantBranches();

    if(this.$route.params.type == "edit"){
      if(this.$route.params.id){
        sessionStorage.setItem('complianceTemplateID', this.$route.params.id);
      } 
    }else{
      sessionStorage.setItem('complianceTemplateID', 0);
    }
    
    this.complianceTemplateID = sessionStorage.getItem('complianceTemplateID')
    
    if(this.complianceTemplateID > 0){
      this.getcomplianceTemplateByID(this.complianceTemplateID);
      this.isEdit = true;     
    }

    if(this.isEdit==1 && sessionStorage.getItem('corpMealPlanID')>0){
      //   this.corpMealPlanID = sessionStorage.getItem('corpMealPlanID');
      //   this.getCorporateMealPlan();
      //   this.isEdit = 1;
    }
  },

  middleware: "authentication",
};
</script>

<template lang="en">
<Layout>
    <PageHeader :title="title" :items="items" />
    <div class="alertMsg">
      <b-alert v-model="showDismissibleAlert" variant="success" dismissible>
          Template Created Succesfully!
      </b-alert>
    </div>
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6">
                        <label class="form-label" for="formrow-templateTitle-input">Template Title*</label>
                        <input type="text" class="form-control" id="formrow-templateTitle-input" v-model="templateTitle"
                            :class="{
                            'is-invalid': submitted && $v.templateTitle.$error,
                          }">
                        <div v-if="submitted && $v.templateTitle.$error" class="invalid-feedback">
                            <span v-if="!$v.templateTitle.required">This value is required.</span>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label class="form-label" for="formrow-endTime-input">Select Restaurant Branch*</label>
                        <multiselect v-model="restBranchID" :disabled="isEdit" :options="restBranchArr" track-by="restBranchID" label="restaurantName"
                            :class="{'is-invalid': submitted && $v.restBranchID.$error,}"></multiselect>
                        <div v-if="submitted && $v.restBranchID.$error" class="invalid-feedback">
                            <span v-if="!$v.restBranchID.required">This value is required.</span>
                        </div>
                    </div>
                </div> 
            </div> 
        </div>
    </div>

    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <h6> Create Task List </h6>
                <div class="inner-repeater mb-4">
                    <div class="inner mb-3">
                        <div v-for="(data, index) in taskArr" :key="data.id" class="inner mb-3 row">
                            <div class="col-md-3">
                                <label class="form-label" >Task List : *</label>
                                <input class="form-control" type="text"  v-model="data.tasklist">
                            </div>
                            <div class="col-md-3">
                                <label class="form-label" >Weightage *</label>
                                <multiselect v-model="data.weightage" :options="weightageArr" :show-labels="false"></multiselect>
                            </div>
                            <div class="col-md-3">
                                <label class="form-label" >Max Score *</label>
                                <input class="form-control" type="number"  v-model="data.maxScore">
                            </div>
                            <div class="col-lg-2 align-self-center d-grid" style="top: 10px;">
                                <input type="button" class="btn btn-themeBrown btn-block inner" value="Delete" @click="deleteItem(index,data.reportTaskID)"/>
                            </div>                         
                        </div>
                    </div>
                    <input type="button" class="btn btn-themeOrange inner" value="Add new item" @click="AddItem"/>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <h6>Total:</h6>
                    </div>
                    <div class="col-md-3">
                        <h6>{{totalWeightagePercent}} % </h6>
                    </div>
                    <div class="col-md-3">
                        <h6>{{totalScore}} </h6>
                    </div>
                </div>
                <div class ="row" style="float:right;">
                    <b-spinner v-if="loading" class="m-2 col-3" variant="primary" role="status"></b-spinner>
                    <button type="button" class="col-3 btn btn-themeYellow w-md waves-effect waves-light" 
                        :disabled="loading" @click="isEdit ? updateForm() : submitForm()">
                        Submit
                    </button>
                </div>
            </div> 
        </div>
    </div>
</Layout>
</template>

<style>
.alertMsg{
  position: fixed;
  right: 0px;
  z-index: 500;
  top: 79px;
  width: 22%;
}
</style>